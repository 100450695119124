module.exports = {
	"SEX": {
		"FEMALE": {
			"label": "女",
			"value": 2
		},
		"MALE": {
			"label": "男",
			"value": 1
		}
	},
	"VISION": {
		"NORMAL": {
			"label": "视力正常",
			"value": 1
		},
		"ABNORMAL": {
			"label": "视力异常",
			"value": 2
		}
	},
	"CHECK_STATUS": {
		"NORMAL": {
			"label": "正常",
			"value": 1
		},
		"ABNORMAL": {
			"label": "异常",
			"value": 0
		}
	},
	"REGISTRATION_STATUS": {
		"WAIT_PAY": {
			"value": 1,
			"label": "待支付"
		},
		"FINISH": {
			"value": 2,
			"label": "已完成"
		},
		"CANCEL": {
			"value": 3,
			"label": "已取号"
		},
		"EXPIRED": {
			"value": 4,
			"label": "已过期"
		}
	},
	"DOCTOR_POSITION": {
		"ARCHIATER": {
			"label": "主任医师",
			"value": 1
		},
		"DEPUTY_ARCHIATER": {
			"label": "副主任医师",
			"value": 2
		},
		"ATTENDING_PHYSICIAN": {
			"label": "主治医师",
			"value": 3
		},
		"PHYSICIAN": {
			"label": "住院医师",
			"value": 4
		},
		"MEDIASTINUS": {
			"label": "助理医师",
			"value": 5
		},
		"JUNIOR_OPTOMETRIST": {
			"label": "初级验光员",
			"value": 6
		},
		"MIDDLE_OPTOMETRIST": {
			"label": "中级验光员",
			"value": 7
		},
		"SENIOR_OPTOMETRIST": {
			"label": "高级验光员",
			"value": 8
		},
		"NATIONAL_GRADE_II_OPTOMETRIST": {
			"label": "国家二级视光技师",
			"value": 9
		},
		"NATIONAL_GRADE_I_OPTOMETRIST": {
			"label": "国家一级验光技师",
			"value": 10
		},
		"OPTOMETRIST_OFFICER": {
			"label": "视光主任",
			"value": 11
		},
		"OPTOMETRIST_SPECIALIST": {
			"label": "视光专家",
			"value": 12
		}
	},
	"TOOTH_TYPE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "龋齿"
		},
		"OTHER": {
			"value": 2,
			"label": "其他"
		}
	},
	"PERIODONTAL_TYPE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "结石"
		},
		"OTHER": {
			"value": 2,
			"label": "其他"
		}
	},
	"DENTITION_TYPE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"OTHER": {
			"value": 2,
			"label": "上颚前突"
		},
		"DISEASE": {
			"value": 3,
			"label": "下牙后缩"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "不正常"
		}
	},
	"LATERAL_BEND_DEGREES": {
		"ONE": {
			"value": 1,
			"label": "I度"
		},
		"TWO": {
			"value": 2,
			"label": "II度"
		},
		"THIRD": {
			"value": 3,
			"label": "III度"
		}
	},
	"FRONT_BACK_BEND1": {
		"NOT_HAVE": {
			"value": 1,
			"label": "无前后弯曲异常"
		},
		"STRAIGHT_BACK": {
			"value": 2,
			"label": "直背"
		},
		"FRONT_BEND": {
			"value": 3,
			"label": "前凸异常"
		},
		"BACK_BEND": {
			"value": 4,
			"label": "后凸异常"
		}
	},
	"TNDEMMIRRORA": {
		"NOUSETNDEMMIRRORA": {
			"value": 0,
			"label": "无需使用串镜矫正"
		},
		"POSITIVEDECLINESNEGATIVERISES": {
			"value": -1,
			"label": "正片下降，负片上升"
		},
		"POSITIVERISINGNEGATIVEFALLING": {
			"value": 1,
			"label": "正片上升，负片下降"
		},
		"OTHERCIRCUMSTANCES": {
			"value": 2,
			"label": "其他情况"
		},
		"UNTESTED": {
			"value": 9,
			"label": "未测试"
		}
	},
	"AMETROPIA": {
		"NORMAL": {
			"value": 0,
			"label": "正常"
		},
		"MYOPIA": {
			"value": 1,
			"label": "近视"
		},
		"HYPEROPIA": {
			"value": 2,
			"label": "远视"
		},
		"OTHER": {
			"value": 3,
			"label": "其他情况（疾病等其它原因）"
		},
		"UNTESTED": {
			"value": 9,
			"label": "未测试"
		}
	},
	"SCHOOL_TYPE": {
		"NURSERY_SCHOOL": {
			"label": "幼儿园",
			"value": 4
		},
		"PRIMARY_SCHOOL": {
			"label": "小学",
			"value": 1,
			"questionNaireKey": "PSHIQ"
		},
		"JUNIOR_HIGH_SCHOOL": {
			"label": "初中",
			"value": 2,
			"questionNaireKey": "MSHIQ"
		},
		"SENIOR_HIGH_SCHOOL": {
			"label": "高中",
			"value": 3,
			"questionNaireKey": "MSHIQ"
		},
		"PROFESSIONAL_HIGH_SCHOOL": {
			"label": "职高",
			"value": 5,
			"questionNaireKey": "MSHIQ"
		},
		"COLLEGE": {
			"label": "大学",
			"value": 6,
			"questionNaireKey": "USHIQ"
		}
	},
	"WEARINGGLASSESTYPE": {
		"FRAME_GLASSES": {
			"value": 1,
			"label": "框架眼镜"
		},
		"CONTACT_LENSES": {
			"value": 2,
			"label": "隐形眼镜"
		},
		"NIGHT_WEARING_ORTHOKERATOLOGY_LENS": {
			"value": 3,
			"label": "夜戴角膜塑形镜"
		},
		"DONT_WEAR_GLASSES": {
			"value": 4,
			"label": "不戴镜"
		}
	},
	"MENSTRUATIONANDSPERMATORRHEA": {
		"VALUENO": {
			"value": 1,
			"label": "否"
		},
		"VALUEYES": {
			"value": 2,
			"label": "是"
		},
		"DEFECT": {
			"value": 9,
			"label": "缺失"
		}
	},
	"ANALYSIS_BMI": {
		"OVERWEIGHT": {
			"value": 2,
			"label": "超重"
		},
		"FAT": {
			"value": 3,
			"label": "肥胖"
		},
		"MILD_EMACIATION": {
			"value": 4,
			"label": "轻度消瘦"
		},
		"MODERATE_SEVERE_EMACIATION": {
			"value": 5,
			"label": "中重度消瘦"
		}
	},
	"FORM_ITEM_TYPE": {
		"NUM_INPUT": {
			"value": 1,
			"label": "数值输入",
			"type": "Number",
			"submitValueKey": "numValue"
		},
		"NUM_INPUT_SELECT": {
			"value": 2,
			"label": "数值单选",
			"type": "Number",
			"submitValueKey": "selectValue",
			"params": {
				"showOptions": true,
				"inputPattern": "^(-?\\d+)(\\.\\d+)?",
				"inputErrorMessage": "必须是数字类型",
				"submitAttr": "optionNumValue"
			}
		},
		"NORMAL_SELECT": {
			"value": 3,
			"label": "普通单选",
			"type": "String",
			"submitValueKey": "selectValue",
			"params": {
				"showOptions": true,
				"submitAttr": "optionText"
			}
		},
		"NORMAL_MULT_SELECT": {
			"value": 4,
			"label": "普通多选",
			"type": "Array",
			"submitValueKey": "selectValue",
			"params": {
				"showOptions": true,
				"submitAttr": "optionText"
			}
		},
		"STRING_INPUT": {
			"value": 5,
			"label": "字符输入",
			"type": "String",
			"submitValueKey": "stringValue"
		},
		"IMAGE_INPUT": {
			"value": 6,
			"label": "图片输入"
		},
		"IMAGE_MULTI_INPUT": {
			"value": 7,
			"label": "多图片输入"
		},
		"FILL_SELECT": {
			"value": 8,
			"label": "录入单选"
		},
		"FILL_MULT_SELECT": {
			"value": 9,
			"label": "录入多选"
		}
	},
	"GENITOURINARY": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"REDUNDANT_PREPUCE": {
			"value": 2,
			"label": "包皮过长"
		},
		"PHIMOSIS": {
			"value": 3,
			"label": "包茎"
		},
		"CRYPTORCHIDISM": {
			"value": 4,
			"label": "隐睾"
		},
		"REDUNDANT_PREPUCE_AND_CRYPTORCHIDISM": {
			"value": 5,
			"label": "包皮过长、隐睾"
		},
		"PHIMOSIS_AND_CRYPTORCHIDISM": {
			"value": 6,
			"label": "包茎、隐睾"
		},
		"SCROTAL_HERNIA": {
			"value": 7,
			"label": "阴囊疝"
		},
		"VARICOCELE": {
			"value": 8,
			"label": "精索静脉曲张"
		},
		"SLIGHT_MEMBRANOUS_EFFUSION": {
			"value": 9,
			"label": "鞘膜积液"
		},
		"HERNIA": {
			"value": 10,
			"label": "疝气"
		},
		"CONCEALED_PENIS": {
			"value": 11,
			"label": "隐匿性阴茎"
		},
		"EXCESSIVE_SMEGMA_ON_THE_FORESKIN": {
			"value": 12,
			"label": "包皮垢多"
		},
		"NO_CHECK": {
			"value": 13,
			"label": "儿童拒绝检查"
		}
	},
	"FEMALEBREAST": {
		"NORMAL_DEVELOPMENT": {
			"value": 1,
			"label": "正常发育"
		},
		"CAKING": {
			"value": 2,
			"label": "结块"
		},
		"OTHER": {
			"value": 3,
			"label": "其他"
		}
	},
	"YESORNO": {
		"VALUEYES": {
			"value": 1,
			"label": "是"
		},
		"VALUENO": {
			"value": 2,
			"label": "否"
		}
	},
	"EXPORT_DATA_TYPE": {
		"NATIONAL_STANDAND": {
			"value": 1,
			"label": "国标"
		},
		"LONGARITHM": {
			"value": 2,
			"label": "对数"
		}
	},
	"NORMAL_OPTIONS": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"HERNIA": {
			"value": 3,
			"label": "疝气"
		}
	},
	"LUNG": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"WHEEZING_RALE": {
			"value": 3,
			"label": "哮鸣音"
		},
		"DRY_AND_WET_RALES": {
			"value": 4,
			"label": "干湿性啰音"
		},
		"DRY_RALE": {
			"value": 5,
			"label": "干啰音"
		},
		"MOIST_RALE": {
			"value": 6,
			"label": "湿啰音"
		},
		"STRIDOR": {
			"value": 7,
			"label": "喘鸣音"
		},
		"COARSE_BREATHING_SOUND": {
			"value": 8,
			"label": "呼吸音粗"
		},
		"OTHER": {
			"value": 9,
			"label": "其他"
		}
	},
	"SPLEEN": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"MILD_SWELLING": {
			"value": 2,
			"label": "轻度肿大"
		},
		"MODERATE_SWELLING": {
			"value": 3,
			"label": "中度肿大"
		},
		"SEVERE_SWELLING": {
			"value": 4,
			"label": "重度肿大"
		},
		"ABNORMAL": {
			"value": 5,
			"label": "异常"
		},
		"OTHER": {
			"value": 6,
			"label": "其他"
		}
	},
	"LIVER": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"SWELLING": {
			"value": 3,
			"label": "肿大"
		},
		"OTHER": {
			"value": 4,
			"label": "其他"
		}
	},
	"BELLY": {
		"SOFT_WITHOUT_TENDERNESS": {
			"value": 1,
			"label": "平软无压痛"
		},
		"ENCLOSED_MASS": {
			"value": 2,
			"label": "包块"
		},
		"THERE_IS_TENDERNESS": {
			"value": 3,
			"label": "有压痛"
		},
		"OTHER": {
			"value": 4,
			"label": "其他"
		}
	},
	"DENTITIONS": {
		"NEAT": {
			"value": 1,
			"label": "整齐"
		},
		"UNEVEN": {
			"value": 2,
			"label": "不齐"
		}
	},
	"NECK": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"THYROID_ENLARGEMENT": {
			"value": 3,
			"label": "甲状腺增大"
		},
		"CAROTID_DISTENSION": {
			"value": 4,
			"label": "颈动脉怒张或搏动"
		},
		"ENCLOSED_MASS": {
			"value": 5,
			"label": "包块"
		},
		"TRACHEA_MOVEMENT": {
			"value": 6,
			"label": "有气管移位"
		},
		"TORTICOLLIS": {
			"value": 7,
			"label": "斜颈"
		},
		"FACIAL_ASYMMETRY": {
			"value": 8,
			"label": "面部不对称"
		},
		"CERVICAL_SPONDYLOPATHY": {
			"label": "颈椎病",
			"value": 10
		},
		"OTHER": {
			"value": 9,
			"label": "其他"
		}
	},
	"THYROID": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"ONE_LEVEL_SWELLING": {
			"value": 3,
			"label": "1级肿大"
		},
		"TWO_LEVEL_SWELLING": {
			"value": 4,
			"label": "2级肿大"
		},
		"THREE_LEVEL_SWELLING": {
			"value": 5,
			"label": "3级肿大"
		},
		"TUBERCLE": {
			"value": 6,
			"label": "结节"
		},
		"TENDERNESS": {
			"value": 7,
			"label": "压痛"
		},
		"TREMOR": {
			"value": 8,
			"label": "震颤"
		},
		"OTHER": {
			"value": 9,
			"label": "其他"
		}
	},
	"CHEST": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"CHICKEN_BREAST": {
			"value": 3,
			"label": "鸡胸"
		},
		"FUNNEL_CHEST": {
			"value": 4,
			"label": "漏斗胸"
		},
		"FLAT_CHEST": {
			"value": 5,
			"label": "扁平胸"
		},
		"MEDIASTINAL_TUMOR_RESECTION": {
			"value": 6,
			"label": "纵膈肿瘤术"
		},
		"THORACIC_ASYMMETRY": {
			"value": 7,
			"label": "胸廓不对称"
		},
		"BARREL_CHEST": {
			"value": 8,
			"label": "桶状胸"
		},
		"COSTAL_VALGUS": {
			"value": 9,
			"label": "肋外翻"
		},
		"SHIELD_CHEST": {
			"value": 10,
			"label": "盾状胸"
		},
		"OTHER": {
			"value": 11,
			"label": "其他"
		}
	},
	"SPINE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"SIDE_BEND_1_DEGREE": {
			"value": 3,
			"label": "侧弯1度"
		},
		"SIDE_BEND_2_DEGREE": {
			"value": 4,
			"label": "侧弯2度"
		},
		"SIDE_BEND_3_DEGREE": {
			"value": 5,
			"label": "侧弯3度"
		},
		"SPINAL_DEFORMITY": {
			"value": 6,
			"label": "脊柱畸形"
		},
		"KYPHOSIS": {
			"value": 7,
			"label": "前后凸"
		},
		"HUMPBACK": {
			"value": 8,
			"label": "驼背"
		},
		"POOR_POSTURE": {
			"value": 9,
			"label": "姿态不良"
		},
		"ABNORMAL_SCOLIOSIS": {
			"value": 10,
			"label": "脊柱侧弯异常"
		},
		"ABNORMAL_SPINAL_LORDOSIS": {
			"value": 11,
			"label": "脊柱前凸异常"
		},
		"ABNORMAL_SPINAL_KYPHOSIS": {
			"value": 12,
			"label": "脊柱后凸异常"
		},
		"OTHER": {
			"value": 13,
			"label": "其他"
		}
	},
	"LIMB": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"O_LEG": {
			"value": 3,
			"label": "O型腿"
		},
		"SYNDACTYLY": {
			"value": 4,
			"label": "并指畸形"
		},
		"PIGEON_TOB": {
			"value": 5,
			"label": "内八字"
		},
		"X_LEG": {
			"value": 6,
			"label": "X型腿"
		},
		"JOINT_DEFORMITY": {
			"value": 7,
			"label": "关节畸形"
		},
		"LIMITED_JOINT_FUNCTION": {
			"value": 8,
			"label": "关节功能受限"
		},
		"TRAUMA": {
			"value": 9,
			"label": "外伤"
		},
		"LIMB_DEFORMITY": {
			"value": 10,
			"label": "四肢残缺"
		},
		"POST_POLIO_SYNDROME": {
			"value": 11,
			"label": "小儿麻痹后遗症"
		},
		"LIMP": {
			"value": 12,
			"label": "跛行"
		},
		"MUSCULAR_ATROPHY": {
			"value": 13,
			"label": "肌肉萎缩"
		},
		"POLYDACTYLY": {
			"value": 14,
			"label": "多指(趾)"
		},
		"JOINT_SWELLING": {
			"value": 15,
			"label": "关节肿胀"
		},
		"LIMB_AGENESIS": {
			"value": 16,
			"label": "缺如/缺头"
		},
		"OTHER": {
			"value": 17,
			"label": "其他"
		}
	},
	"SKIN": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"ECZEMA": {
			"value": 3,
			"label": "湿疹"
		},
		"TINEA": {
			"value": 4,
			"label": "皮肤癣"
		},
		"VITILIGO": {
			"value": 5,
			"label": "白癜风"
		},
		"SURGICAL_OR_TRAUMATIC_SCAR": {
			"value": 6,
			"label": "手术或外伤瘢痕"
		},
		"HEMANGIOMA_SCABIES": {
			"value": 7,
			"label": "血管瘤/疥疮"
		},
		"LCHTHYOSIS": {
			"value": 8,
			"label": "鱼鳞病"
		},
		"DERMATITIS": {
			"value": 9,
			"label": "皮炎"
		},
		"FLUSH": {
			"value": 10,
			"label": "潮红"
		},
		"PALLOR": {
			"value": 11,
			"label": "苍白"
		},
		"PIGMENTATION": {
			"value": 12,
			"label": "色素沉着/斑"
		},
		"YELLOWNESS": {
			"value": 13,
			"label": "黄染"
		},
		"NEVUS": {
			"value": 14,
			"label": "痣"
		},
		"RASH": {
			"value": 16,
			"label": "皮疹"
		},
		"SPIDER_MOLE": {
			"value": 17,
			"label": "蜘蛛痣"
		},
		"EDEMA": {
			"value": 18,
			"label": "水肿"
		},
		"PURPURA": {
			"value": 19,
			"label": "紫癜"
		},
		"SCAR": {
			"value": 20,
			"label": "瘢痕"
		},
		"OTHER": {
			"value": 15,
			"label": "其他"
		}
	},
	"LYMPH_GLAND": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"SWELLING": {
			"value": 3,
			"label": "肿大"
		},
		"SUPRACLAVICULAR": {
			"value": 4,
			"label": "锁骨上"
		},
		"ARMPIT": {
			"value": 5,
			"label": "腋窝"
		},
		"OTHER": {
			"value": 6,
			"label": "其他"
		},
		"NECK": {
			"value": 7,
			"label": "颈部"
		},
		"INGUINAL_LYMPH_NODE_ENLARGEMENT": {
			"value": 8,
			"label": "腹股沟淋巴结肿大"
		}
	},
	"HEAD": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"SQUARE_CRANIUM": {
			"value": 3,
			"label": "方颅"
		},
		"MEGACRANIUM": {
			"value": 4,
			"label": "巨颅"
		},
		"CERVICAL_SPONDYLOPATHY": {
			"value": 5,
			"label": "颈椎病"
		},
		"FACIAL_ASYMMETRY": {
			"value": 6,
			"label": "面部不对称"
		},
		"ABNORMAL_HEAD_MOVEMENT": {
			"value": 7,
			"label": "头部运动异常"
		},
		"OTHER": {
			"value": 8,
			"label": "其他"
		}
	},
	"NOSE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"RHINITIS": {
			"value": 3,
			"label": "鼻炎"
		},
		"NASOSINUSITIS": {
			"value": 4,
			"label": "鼻窦炎"
		},
		"DEVIATION_OF_NASAL_SEPTUM": {
			"value": 5,
			"label": "鼻中隔偏曲"
		},
		"NASAL_POLYP": {
			"value": 6,
			"label": "鼻息肉"
		},
		"EPISTAXIS": {
			"value": 7,
			"label": "鼻衄"
		},
		"VESTIBULITIS": {
			"value": 8,
			"label": "鼻前庭炎"
		},
		"CHRONIC_RHINITIS": {
			"value": 9,
			"label": "慢性鼻炎"
		},
		"OTHER": {
			"value": 10,
			"label": "其他"
		},
		"MALFORMATION": {
			"value": 11,
			"label": "畸形"
		},
		"SWELLING": {
			"value": 12,
			"label": "肿胀"
		},
		"TENDERNESS": {
			"value": 13,
			"label": "压痛"
		}
	},
	"TONSIL_NEW": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"SWELLING_1": {
			"value": 2,
			"label": "1度肿大"
		},
		"SWELLING_2": {
			"value": 3,
			"label": "2度肿大"
		},
		"SWELLING_3": {
			"value": 4,
			"label": "3度肿大"
		},
		"CHRONIC_TONSILLITIS": {
			"value": 5,
			"label": "慢性扁桃体炎"
		},
		"ACUTE_TONSILLITIS": {
			"value": 6,
			"label": "急性扁桃体炎"
		},
		"OTHER": {
			"value": 7,
			"label": "其他"
		},
		"CONGESTION": {
			"value": 8,
			"label": "充血"
		},
		"SECRETION": {
			"value": 9,
			"label": "分泌物"
		}
	},
	"DENTAL_PERIPHERY": {
		"NIL": {
			"value": 1,
			"label": "无"
		},
		"DENTAL_CALCULUS": {
			"value": 2,
			"label": "牙结石"
		},
		"GINGIVITIS": {
			"value": 4,
			"label": "牙龈炎"
		},
		"DENTAL_CALCULUS_AND_GINGIVITIS": {
			"value": 8,
			"label": "牙结石、牙龈炎"
		},
		"PERIODONTITIS": {
			"value": 16,
			"label": "牙周炎"
		},
		"DENTAL_FLUOROSIS": {
			"value": 32,
			"label": "氟斑牙"
		},
		"MALOCCLUSION": {
			"value": 64,
			"label": "错颌"
		},
		"ENAMEL_HYPOPLASIA": {
			"value": 128,
			"label": "牙釉质发育不全"
		},
		"DENTURE_OR_OTHER_DENTAL_DISEASES": {
			"value": 256,
			"label": "义齿/其他牙病"
		},
		"SUPERFICIAL_PERIODONTAL_POCKET": {
			"value": 512,
			"label": "浅牙周袋"
		},
		"DEEP_PERIODONTAL_POCKET": {
			"value": 1024,
			"label": "深牙周袋"
		},
		"OTHER": {
			"value": 2048,
			"label": "其他"
		}
	},
	"TUBERCULIN_PHENOMENON": {
		"NEGATIVE": {
			"value": 1,
			"label": "阴性"
		},
		"WEAK_POSITIVE": {
			"value": 2,
			"label": "弱阳性"
		},
		"MODERATELY_POSITIVE": {
			"value": 3,
			"label": "中度阳性"
		},
		"STRONG_POSITIVE": {
			"value": 4,
			"label": "强阳性"
		}
	},
	"ASCARIS_OVUM": {
		"NEGATIVE": {
			"value": 1,
			"label": "阴性"
		},
		"POSITIVE": {
			"value": 2,
			"label": "阳性"
		}
	},
	"ABO_RED_BLOOD_CELL_STEREO": {
		"A_TYPE": {
			"value": 1,
			"label": "A型"
		},
		"B_TYPE": {
			"value": 2,
			"label": "B型"
		},
		"AB_TYPE": {
			"value": 3,
			"label": "AB型"
		},
		"O_TYPE": {
			"value": 4,
			"label": "O型"
		}
	},
	"HEART": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"HEART_MURMUR": {
			"value": 3,
			"label": "心脏杂音"
		},
		"TACHYCARDIA": {
			"value": 4,
			"label": "心动过速"
		},
		"BRADYCARDIA": {
			"value": 5,
			"label": "心动过缓"
		},
		"CONGENITAL_HEART_DISEASE": {
			"value": 6,
			"label": "先天心脏病"
		},
		"ARRHYTHMIA": {
			"value": 7,
			"label": "心率不齐"
		},
		"SECONDARY_CARDIAC_MURMUR": {
			"value": 8,
			"label": "心脏二级杂音"
		},
		"HEART_MURMUR_ABOVE_GRADE_3": {
			"value": 9,
			"label": "心脏三级以上杂音"
		},
		"FREQUENT_PREMATURE_BEATS": {
			"value": 10,
			"label": "频发早搏"
		},
		"OTHER": {
			"value": 12,
			"label": "其他"
		}
	},
	"HEARTNATURE": {
		"SYSTOLE": {
			"value": 1,
			"label": "性质(收缩期)"
		},
		"DIASTOLE": {
			"value": 2,
			"label": "性质(舒张期)"
		}
	},
	"HEARTSTRENGTH": {
		"STRENGTH_1_LEVEL": {
			"value": 1,
			"label": "强度1级"
		},
		"STRENGTH_2_LEVEL": {
			"value": 2,
			"label": "强度2级"
		},
		"STRENGTH_3_LEVEL": {
			"value": 3,
			"label": "强度3级"
		},
		"STRENGTH_4_LEVEL": {
			"value": 4,
			"label": "强度4级"
		}
	},
	"CHEST_X_RAY": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"POSITIVE": {
			"value": 2,
			"label": "阳性"
		}
	},
	"HEARING": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"HEARING_LOSS": {
			"value": 3,
			"label": "听力减退"
		}
	},
	"EAR": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"CHICKEN_CHEST": {
			"value": 3,
			"label": "耵聍"
		},
		"FUNNEL_CHEST": {
			"value": 4,
			"label": "附耳"
		},
		"FLAT_CHEST": {
			"value": 5,
			"label": "中耳炎"
		},
		"MEDIASTINAL_TUMOR_RESECTION": {
			"value": 6,
			"label": "耳前瘘管"
		},
		"DEAF": {
			"value": 7,
			"label": "耳聋"
		},
		"TYMPANIC_MEMBRANE_PERFORATION": {
			"value": 8,
			"label": "鼓膜穿孔"
		},
		"TYMPANIC_MEMBRANE_REPAIR": {
			"value": 9,
			"label": "鼓膜修补"
		},
		"DYSAUDIA": {
			"value": 10,
			"label": "听力障碍"
		},
		"OTITIS_EXTERNA": {
			"value": 11,
			"label": "外耳炎"
		},
		"SECRETION": {
			"value": 12,
			"label": "分泌物"
		},
		"OTHER": {
			"value": 13,
			"label": "其他"
		}
	},
	"OPRATION_SYMBOL": {
		"GREATER_THAN": {
			"value": 1,
			"label": "大于"
		},
		"LESS_THAN": {
			"value": 2,
			"label": "小于"
		}
	},
	"HEIGHT_WEIGHT_LEVEL": {
		"ONE": {
			"value": 1,
			"label": "上"
		},
		"TWO": {
			"value": 2,
			"label": "中"
		},
		"THREE": {
			"value": 3,
			"label": "下"
		}
	},
	"STANDARD_DEVIATION": {
		"ONE": {
			"value": "-3",
			"label": "-3SD"
		},
		"TWO": {
			"value": "-2",
			"label": "-2SD"
		},
		"THREE": {
			"value": "-1",
			"label": "-1SD"
		},
		"FOUR": {
			"value": "1",
			"label": "+1SD"
		},
		"FIVE": {
			"value": "2",
			"label": "+2SD"
		},
		"SIX": {
			"value": "3",
			"label": "+3SD"
		},
		"SEVEN": {
			"value": "0",
			"label": "中位数"
		}
	},
	"EYEPOSITION": {
		"LEVEL1": {
			"value": 1,
			"label": "内斜"
		},
		"LEVEL2": {
			"value": 2,
			"label": "外斜"
		},
		"LEVEL3": {
			"value": 3,
			"label": "正位"
		}
	},
	"NUTRITION": {
		"NORMAL": {
			"value": 1,
			"label": "未见异常"
		},
		"THIN": {
			"value": 2,
			"label": "消瘦"
		},
		"OVERWEIGHT": {
			"value": 4,
			"label": "超重"
		},
		"LOWWERIGHT": {
			"value": 8,
			"label": "低体重"
		},
		"SLOW": {
			"value": 16,
			"label": "生长迟缓"
		},
		"LIGHTOBESITY": {
			"value": 32,
			"label": "轻度肥胖"
		},
		"MODERATEOBESITY": {
			"value": 64,
			"label": "中度肥胖"
		},
		"SEVEREOBESITY": {
			"value": 128,
			"label": "重度肥胖"
		},
		"LIGHTINNUTRITION": {
			"value": 256,
			"label": "轻度营养不良"
		},
		"MODERATEINNUTRITION": {
			"value": 512,
			"label": "中度营养不良"
		},
		"SEVEREINNUTRITION": {
			"value": 1024,
			"label": "严重慢性营养不良"
		},
		"OBESITY": {
			"value": 2048,
			"label": "肥胖"
		},
		"ABNORMAL": {
			"value": 4096,
			"label": "其他"
		}
	},
	"PROTEIN": {
		"NOTHING": {
			"value": 0,
			"label": "无"
		},
		"MODERATELOWWEIGHT": {
			"value": 4,
			"label": "中度低体重"
		},
		"MODERATESLOW": {
			"value": 5,
			"label": "中度生长迟缓"
		},
		"MODERATETHIN": {
			"value": 6,
			"label": "中度消瘦"
		},
		"SEVERELOWWEIGHT": {
			"value": 7,
			"label": "重度低体重"
		},
		"SEVERESLOW": {
			"value": 8,
			"label": "重度生长迟缓"
		},
		"SEVERETHIN": {
			"value": 9,
			"label": "重度消瘦"
		}
	},
	"OBESITY": {
		"NORMAL": {
			"value": 0,
			"label": "无"
		},
		"OVERWEIGHT": {
			"value": 10,
			"label": "超重"
		},
		"OBESITY": {
			"value": 11,
			"label": "肥胖"
		}
	},
	"_SKIN": {
		"NORMAL": {
			"value": 1,
			"label": "未见异常"
		},
		"ECZEMA": {
			"value": 2,
			"label": "湿疹"
		},
		"EROSION": {
			"value": 3,
			"label": "糜烂"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "其他"
		}
	},
	"AGREEMENT1": {
		"NORMAL": {
			"value": 0,
			"label": "未见异常"
		},
		"ABNORMAL": {
			"value": 1,
			"label": "其他"
		}
	},
	"AGREEMENT2": {
		"NORMAL": {
			"value": 1,
			"label": "未见异常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		}
	},
	"MALFORMATION": {
		"NORMAL": {
			"value": 1,
			"label": "无"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "有"
		}
	},
	"GUIDANCE": {
		"SCIENCEFEED": {
			"value": 1,
			"label": "科学喂养"
		},
		"GROW": {
			"value": 2,
			"label": "生长发育"
		},
		"PREVENTION": {
			"value": 3,
			"label": "疾病预防"
		},
		"ORALCAVITY": {
			"value": 5,
			"label": "口腔保健"
		},
		"PREVENTIONACCIDENT": {
			"value": 4,
			"label": "预防意外伤害"
		},
		"ABNORMAL": {
			"value": 6,
			"label": "其他"
		}
	},
	"FRONTALSUTURE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"EXPAND": {
			"value": 2,
			"label": "膨隆"
		},
		"SUNKEN": {
			"value": 3,
			"label": "凹陷"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "其他"
		}
	},
	"SCOLIOSIS_INSPECT": {
		"NOT_HAVE": {
			"value": 1,
			"label": "正常"
		},
		"TWO": {
			"value": 2,
			"label": "双肩不等高"
		},
		"THREE": {
			"value": 4,
			"label": "双侧肩胛骨下角不等高"
		},
		"FOUR": {
			"value": 8,
			"label": "两侧腰凹不对称"
		},
		"FIVE": {
			"value": 16,
			"label": "双侧髂嵴不等高"
		},
		"SIX": {
			"value": 32,
			"label": "棘突连线倾斜或偏离正中线"
		}
	},
	"LATERAL_BEND": {
		"NOT_HAVE": {
			"value": 1,
			"label": "无侧弯"
		},
		"LEFT_BEND": {
			"value": 2,
			"label": "左低右高"
		},
		"RIGHT_BEND": {
			"value": 3,
			"label": "右低左高"
		},
		"DEFECT": {
			"value": 9,
			"label": "缺失"
		}
	},
	"YESORNO_BOOLEAN": {
		"VALUEYES": {
			"value": true,
			"label": "是"
		},
		"VALUENO": {
			"value": false,
			"label": "否"
		}
	},
	"FRONT_BACK_BEND2": {
		"NOT_HAVE": {
			"value": 1,
			"label": "正常"
		},
		"LORDOSIS_SIGN": {
			"value": 2,
			"label": "前凸体征"
		},
		"KYPHOSIS_SIGN": {
			"value": 3,
			"label": "后凸体征"
		}
	},
	"PRONE_TEXT": {
		"LOSS_CONVEXITY_SIGNS": {
			"label": "前后凸体征消失",
			"value": 1
		},
		"LORDOSIS_SIGN": {
			"label": "前凸体征",
			"value": 2
		},
		"KYPHOSIS_SIGN": {
			"label": "后凸体征",
			"value": 3
		}
	},
	"PRELIMINARY_SCREENING_RESULT": {
		"NORMAL": {
			"label": "正常",
			"value": 1
		},
		"BAD_POSTURE": {
			"label": "姿态不良",
			"value": 2
		},
		"ABNORMAL_LORDOSIS": {
			"label": "脊柱前凸异常",
			"value": 4
		},
		"ABNORMAL_KYPHOSIS": {
			"label": "脊柱后凸异常",
			"value": 8
		},
		"SCOLIOSIS_I": {
			"label": "脊柱侧弯Ⅰ级",
			"value": 16
		},
		"SCOLIOSIS_Ⅱ": {
			"label": "脊柱侧弯Ⅱ级",
			"value": 32
		},
		"SCOLIOSIS_Ⅲ": {
			"label": "脊柱侧弯Ⅲ级",
			"value": 64
		}
	},
	"SPINAL_HISTORY": {
		"NOTHING": {
			"label": "无",
			"value": 1
		},
		"REMEMBER_FAMILY_HISTORY_BENDING_ANOMALIES": {
			"label": "脊柱弯曲异常家族史",
			"value": 2
		},
		"HISTORY_SPINAL_TRAUMA": {
			"label": "脊柱外伤病史",
			"value": 4
		},
		"HISTORY_SPINAL_SURGERY": {
			"label": "脊柱手术病史",
			"value": 8
		}
	},
	"QUESTIONNAIRE_TYPE": {
		"PSHIQ": {
			"label": "小学学生健康状况及影响因素调查表管理",
			"value": 0,
			"key": "Pshiq",
			"api": "bizQuestionnaireSavePshiq"
		},
		"MSHIQ": {
			"label": "中学学生健康状况及影响因素调查表管理",
			"value": 1,
			"key": "Mshiq",
			"api": "bizQuestionnaireSaveMshiq"
		},
		"USHIQ": {
			"label": "大学学生健康状况及影响因素调查表管理",
			"value": 2,
			"key": "Ushiq",
			"api": "bizQuestionnaireSaveUshiq"
		},
		"SPVISQ": {
			"label": "学生视力不良、脊柱弯曲异常及影响因素专项调查表",
			"value": 3,
			"key": "Spvisq",
			"api": "bizQuestionnaireSaveSpvisq"
		},
		"HEALTHQ": {
			"label": "儿童青少年近视调查问卷（家长版）",
			"value": 4,
			"key": "Healthq",
			"api": "bizQuestionnaireSaveCamsq"
		}
	},
	"QUESTION_SUBJECT_TYPE": {
		"TYPE1": {
			"label": "单选",
			"value": 1
		},
		"TYPE2": {
			"label": "多选",
			"value": 2
		},
		"TYPE3": {
			"label": "数字",
			"value": 3
		},
		"TYPE4": {
			"label": "单选+填空",
			"value": 4
		},
		"TYPE5": {
			"label": "多选+填空",
			"value": 5
		},
		"TYPE6": {
			"label": "文字题",
			"value": 6
		},
		"TYPE10": {
			"label": "单选+需要填两空(针对时间 需转换为分钟)",
			"value": 10
		},
		"TYPE11": {
			"label": "时间输入(最终值转换为分钟)",
			"value": 11
		},
		"TYPE12": {
			"label": "单选+两个选项需要填一空",
			"value": 12
		},
		"TYPE22": {
			"label": "4项时间输入",
			"value": 22
		},
		"TYPE23": {
			"label": "2项输入",
			"value": 23
		},
		"TYPE25": {
			"label": "时间输入(最终值转换为小时)",
			"value": 25
		}
	},
	"SCREENING_PLAN_TYPE": {
		"VISION": {
			"label": "视力筛查",
			"value": 1
		},
		"STANDARD_VISION": {
			"label": "国标视力筛查",
			"value": 2
		},
		"PHYSICAL": {
			"label": "全科筛查",
			"value": 3
		},
		"STANDARD_COMMON_DISEASE": {
			"label": "国标常见病筛查",
			"value": 4
		},
		"STUDENT_HEALTH_CHECKUP": {
			"label": "学生健康体检",
			"value": 5
		},
		"YEAR_HEALTH_CHECKUP": {
			"label": "学年健康检查",
			"value": 6
		}
	},
	"VISION_EYEPOSITION": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"INNORMAL": {
			"value": 0,
			"label": "异常"
		}
	},
	"OUTER_EYES": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"HORDEOLUM": {
			"value": 3,
			"label": "麦粒肿"
		},
		"MEIBOMAIN_CYST": {
			"value": 4,
			"label": "霰粒肿"
		},
		"TRICHIASIS": {
			"value": 5,
			"label": "倒睫"
		},
		"OTHER": {
			"value": 6,
			"label": "其他"
		}
	},
	"ARCH": {
		"NORMAL": {
			"label": "无明显异常",
			"value": 0
		},
		"MIDL_FLATFOOT": {
			"label": "轻度扁平足",
			"value": 1
		},
		"MODERATE_FLATFOOT": {
			"label": "中度扁平足",
			"value": 2
		},
		"SEVERE_FLATFOOT": {
			"label": "重度扁平足",
			"value": 3
		},
		"HIGHT_ARCH": {
			"label": "高足弓",
			"value": 4
		}
	},
	"CACHE_KEYS": {
		"QTS": {
			"value": "QTS",
			"label": "儿童青少年近视调查问卷答题数据"
		}
	},
	"HEIGHT_LEVEL": {
		"ONE": {
			"label": "上等",
			"value": 1
		},
		"TWO": {
			"label": "下等",
			"value": 2
		},
		"THREE": {
			"label": "中上等",
			"value": 3
		},
		"FOUR": {
			"label": "中等",
			"value": 4
		},
		"FIVE": {
			"label": "中下等",
			"value": 5
		}
	},
	"WEIGHT_LEVEL": {
		"OVERWEIGHT": {
			"label": "超重",
			"value": 1
		},
		"FAT": {
			"label": "肥胖",
			"value": 2
		},
		"MILD_EMACIATION": {
			"label": "轻度消瘦",
			"value": 3
		},
		"MODERATE_SEVERE_EMACIATION": {
			"label": "中重度消瘦",
			"value": 4
		}
	},
	"WEIGHT_GRADE_LEVEL": {
		"ONE": {
			"label": "上等",
			"value": 1
		},
		"TWO": {
			"label": "下等",
			"value": 2
		},
		"THREE": {
			"label": "中上等",
			"value": 3
		},
		"FOUR": {
			"label": "中等",
			"value": 4
		},
		"FIVE": {
			"label": "中下等",
			"value": 5
		}
	},
	"TRACHOMA": {
		"NORMAL": {
			"value": true,
			"label": "无"
		},
		"INNORMAL": {
			"value": false,
			"label": "有"
		}
	},
	"OBVIOUS_ABNORMALITY": {
		"NO": {
			"value": 1,
			"label": "未见明显异常"
		},
		"YES": {
			"value": 2,
			"label": "异常"
		}
	},
	"NUMBER_OF_PERIODS": {
		"ONE": {
			"value": 1,
			"label": "1期"
		},
		"TWO": {
			"value": 2,
			"label": "2期"
		},
		"THREE": {
			"value": 3,
			"label": "3期"
		},
		"FOUR": {
			"value": 4,
			"label": "4期"
		},
		"FIVE": {
			"value": 5,
			"label": "5期"
		}
	},
	"OTHER_DEVELOPMENTS": {
		"NO": {
			"value": 1,
			"label": "无"
		},
		"SUSPICIOUS_DEVELOPMENT": {
			"value": 2,
			"label": "可疑发育"
		},
		"SEXUAL_PRECOCITY": {
			"value": 3,
			"label": "性早熟"
		},
		"EARLY_PUBERTY_DEVELOPMENT": {
			"value": 4,
			"label": "青春期早发育"
		},
		"ADOLESCENT_DWARFISM": {
			"value": 5,
			"label": "青春期矮小"
		}
	},
	"NEAR_VISION": {
		"ONE": {
			"value": 0.1,
			"label": "0.1"
		},
		"TWO": {
			"value": 0.12,
			"label": "0.12"
		},
		"THIRD": {
			"value": 0.15,
			"label": "0.15"
		},
		"FOUR": {
			"value": 0.2,
			"label": "0.2"
		},
		"SIX": {
			"value": 0.3,
			"label": "0.3"
		},
		"SEVEN": {
			"value": 0.4,
			"label": "0.4"
		},
		"EIGHT": {
			"value": 0.5,
			"label": "0.5"
		},
		"NIGHT": {
			"value": 0.6,
			"label": "0.6"
		},
		"FIVE": {
			"value": 0.7,
			"label": "0.7"
		},
		"TEN": {
			"value": 0.8,
			"label": "0.8"
		},
		"ELEVEN": {
			"value": 1,
			"label": "1.0"
		}
	},
	"DOCTOR_ADVICE": {
		"AGREE": {
			"value": 1,
			"label": "同意入园"
		},
		"SUSPENDED": {
			"value": 2,
			"label": "暂缓入园"
		},
		"OTHER": {
			"value": 3,
			"label": "其他情况"
		}
	},
	"CHECK_RESULT": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"OTHER": {
			"value": 2,
			"label": "其他"
		}
	},
	"EMERGENCE_SITUATION": {
		"NOT_SPROUTING": {
			"value": 1,
			"label": "未萌出"
		},
		"PARTIAL_ERUPTION": {
			"value": 2,
			"label": "部分萌出"
		},
		"COMPLETE_SPROUT": {
			"value": 3,
			"label": "完全萌出"
		}
	},
	"DENTAL_SURFACE_CONDITION": {
		"SHALLOW_PIT_AND_GROOVE": {
			"value": 1,
			"label": "浅窝沟"
		},
		"CARIES_PRESENT": {
			"value": 2,
			"label": "有龋（含黑线）"
		},
		"FILLED": {
			"value": 3,
			"label": "已填充"
		},
		"CLOSED": {
			"value": 4,
			"label": "已封闭"
		},
		"OTHER": {
			"value": 5,
			"label": "此次应封闭"
		}
	},
	"CLOSE_EXECUTION_STATUS": {
		"CLOSED": {
			"value": 1,
			"label": "封闭"
		},
		"UNCLOSED": {
			"value": 2,
			"label": "未封闭"
		}
	},
	"CLOSE_RE_EXECUTION_STATUS": {
		"FALL_OFF": {
			"value": 1,
			"label": "脱落"
		},
		"NOT_DETACHED": {
			"value": 2,
			"label": "未脱落"
		}
	},
	"BMI_LEVEL": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"OVERWEIGHT": {
			"value": 2,
			"label": "超重"
		},
		"FAT": {
			"value": 3,
			"label": "肥胖"
		},
		"MILD_EMACIATION": {
			"value": 4,
			"label": "轻度消瘦"
		},
		"MODERATE_SEVERE_EMACIATION": {
			"value": 5,
			"label": "中重度消瘦"
		},
		"SEVERE_OBESITY": {
			"value": 6,
			"label": "重度肥胖"
		}
	},
	"PSYCHOLOGICAL_BEHAVIORAL_DEVELOPMENT": {
		"ADOPT": {
			"value": 1,
			"label": "通过"
		},
		"NOT_PASSED": {
			"value": 2,
			"label": "不通过"
		},
		"SUSPICIOUS": {
			"value": 3,
			"label": "可疑"
		},
		"NOT_COOPERATING": {
			"value": 4,
			"label": "不配合"
		}
	},
	"CODE_CHECK_OPTIONS": {
		"NAME": {
			"value": 1,
			"key": "name",
			"label": "姓名"
		},
		"CARD": {
			"value": 2,
			"key": "idCard",
			"label": "身份证后四位"
		},
		"GRADE": {
			"value": 4,
			"key": "gradeName",
			"label": "年级"
		},
		"CLASS": {
			"value": 8,
			"key": "className",
			"label": "班级"
		},
		"SCHOOL": {
			"value": 16,
			"key": "schoolName",
			"label": "学校名"
		}
	},
	"PASS_OR_NO_PASS": {
		"PASS": {
			"value": 1,
			"label": "通过"
		},
		"NO_PASS": {
			"value": 2,
			"label": "不通过"
		}
	},
	"HBS_AG": {
		"NEGATIVE": {
			"value": 1,
			"label": "阴性"
		},
		"POSITIVE": {
			"value": 2,
			"label": "阳性"
		},
		"WEAKLY_POSITIVE": {
			"value": 3,
			"label": "弱阳性"
		}
	},
	"GENITALIA_JJFYBJY": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"HERNIA": {
			"value": 3,
			"label": "疝气"
		},
		"CRYPTORCHIDISM": {
			"value": 4,
			"label": "隐睾"
		},
		"CONCEALED_PENIS": {
			"value": 5,
			"label": "隐匿性阴茎"
		},
		"SMALL_PENIS": {
			"value": 6,
			"label": "小阴茎"
		},
		"HYDROCELE": {
			"value": 7,
			"label": "鞘膜积液"
		},
		"EXCESSIVE_SMEGMA_ON_THE_FORESKIN": {
			"value": 8,
			"label": "包皮垢多"
		},
		"REFUSE_TO_CHECK": {
			"value": 9,
			"label": "拒绝检查"
		},
		"APPEARANCE_DEFORMITY": {
			"value": 10,
			"label": "外观畸形"
		},
		"PHIMOSIS_IS_TOO_LONG": {
			"value": 11,
			"label": "包皮过长"
		},
		"PHIMOSIS": {
			"value": 12,
			"label": "包茎"
		},
		"CLITORAL_HYPERTROPHY": {
			"value": 13,
			"label": "阴蒂肥大"
		}
	},
	"PASS_NOT_APPROVED_OTHER": {
		"PASS": {
			"value": 1,
			"label": "通过"
		},
		"NOT_APPROVED": {
			"value": 2,
			"label": "不通过"
		},
		"OTHER": {
			"value": 3,
			"label": "其他"
		}
	},
	"EYE_APPEARANCE": {
		"NORMAL": {
			"value": 1,
			"label": "未见异常"
		},
		"CONJUNCTIVITIS": {
			"value": 3,
			"label": "结膜炎"
		},
		"EYELID_GLAND_INFLAMMATION": {
			"value": 4,
			"label": "睑腺炎"
		},
		"INVERTED_EYELASHES": {
			"value": 5,
			"label": "倒睫"
		},
		"PTOSIS_OF_THE_UPPER_EYELID": {
			"value": 6,
			"label": "上睑下垂"
		},
		"STRABISMUS": {
			"value": 7,
			"label": "斜视"
		},
		"OTHER": {
			"value": 8,
			"label": "其他"
		}
	},
	"SMELL": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "异常"
		},
		"REDUCED_SENSE_OF_SMELL": {
			"value": 3,
			"label": "嗅觉减退"
		},
		"LOSS_OF_SMELL": {
			"value": 4,
			"label": "嗅觉丧失"
		},
		"OTHER": {
			"value": 5,
			"label": "其他"
		}
	},
	"VISION_STATUS": {
		"NORMAL": {
			"value": 1,
			"label": "视力正常"
		},
		"BADNESS": {
			"value": 2,
			"label": "视力不良"
		},
		"MILD_BADNESS": {
			"value": 3,
			"label": "轻度视力不良"
		},
		"MODERATE_BADNESS": {
			"value": 4,
			"label": "中度视力不良"
		},
		"SEVERE_BADNESS": {
			"value": 5,
			"label": "重度视力不良"
		}
	},
	"DIOPTER_STATUS": {
		"NORMAL": {
			"value": 1,
			"label": "屈光正常"
		},
		"ABNORMAL": {
			"value": 2,
			"label": "屈光不正"
		}
	},
	"CHECK_RESULT_2": {
		"ADOPT": {
			"value": 1,
			"label": "通过"
		},
		"NOT_PASSED": {
			"value": 2,
			"label": "不通过"
		},
		"SUSPICIOUS": {
			"value": 3,
			"label": "可疑"
		}
	},
	"CHECK_STATUS_BOOL": {
		"NORMAL": {
			"value": true,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": false,
			"label": "异常"
		}
	}
};